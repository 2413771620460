
import {defineComponent} from "vue";

export default defineComponent({
  props: {
    type: {
      type: String,
      required: false,
      default: () => 'primary'
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: () => false
    },
    isDisabled: {
      type: Boolean,
      required: false,
      default: () => false
    }
  },
  computed: {
    styles(){
      let classes = `btn btn-${this.type}`
      if(this.isLoading){
        classes += ' btn-icon'
      }
      return classes
    }
  }
})
