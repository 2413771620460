
import {defineComponent} from "vue";
import {useUser} from "@/store/pinia/userStore";
import moment from "moment";
import {UserService} from "@/services/UserService";
import {UpdateAccountTermsCommand} from "@/models/user/UpdateAccountTermsCommand";
import Button from "@/components/UI/Button.vue";

export default defineComponent({
  components: {Button},
  setup() {
    return {userStore: useUser()}
  },
  data() {
    return {
      isLoading: false,
      consent: {
        acceptsTos: false,
        acceptsPrivacyPolicy: false,
        emailOptIn: true
      }
    }
  },
  methods: {
    submitUpdatedTerms() {
      // We will assume that this method is only invoked if the user ticked the required boxes
      this.isLoading = true
      UserService.updateTerms({
        tos: this.shouldAcceptTos ? 1 : 2,
        privacyPolicy: this.shouldAcceptPrivacyPolicy ? 1 : 2,
        emailOptIn: this.consent.emailOptIn
      } as UpdateAccountTermsCommand).then(() => {
        this.userStore.overrideUserTerms()
        this.userStore.fetchUser()
        this.$router.push({name: 'user-overview'})
      }).finally(() => {
        this.isLoading = false
      })
    }
  },
  computed: {
    isValid(): boolean {
      return (this.shouldAcceptTos && this.consent.acceptsTos) && (this.shouldAcceptPrivacyPolicy && this.consent.acceptsPrivacyPolicy)
    },
    shouldAcceptTos(): boolean {
      return moment(this.userStore.profile.tosAcceptedOn).isBefore(moment("2022-10-13"))
    },
    shouldAcceptPrivacyPolicy(): boolean {
      return moment(this.userStore.profile.privacyPolicyAcceptedOn).isBefore(moment("2022-10-13"))
    }
  }
})
