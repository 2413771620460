import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = {
  key: 1,
  class: "fad fs-2 fa-spin fa-spinner-third"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass([_ctx.styles, "text-white"]),
    disabled: _ctx.isLoading || _ctx.isDisabled
  }, [
    (!_ctx.isLoading)
      ? _renderSlot(_ctx.$slots, "default", { key: 0 })
      : (_openBlock(), _createElementBlock("i", _hoisted_2))
  ], 10, _hoisted_1))
}